/* eslint-disable import/no-anonymous-default-export */
export default {
  ar: {
    hello: "مرحبا",
    reyial: "ريال",
    rowsFrom: "صفوف من",
    rowFrom: "صف من",
    row: "صف",
    one: "١",
    two: "٢",
    three: "٣",
    four: "٤",
    five: "٥",
    from: "من",
    in: "في",
    back: "رجوع",
    after: 'بعد',
    sec: 'ثانيه',
    endSession: 'أنتهت الجلسة',
    loaderText: "جاري تحميل البيانات...",
    seeMore: "مشاهدة المزيد ...",
    landingPage: {
      navbar: {
        dashboard: "الرئيسية",
        whoWeAre: 'من نحن',
        whoIsMustand: "ما هو مستند",
        clientsReviews: "آراء العملاء",
        commonQuestions: "الأسئلة الشائعة",
        Blogs: "المدونة",
        packages: "الباقات",
        contactUs: "تواصل معنا",
        freeTrial: "تجربة مجانية",
        whyDoYouChooseMustand: 'لماذا تختار مستند ؟ ',
        login: "دخول",
        downloadApp: 'حمل تطبيق مستند الآن, و تابع جميع شئونك القانونية على جوالك'
      },
      header: {
        title: "معا لتحقيق رؤية المملكة للتحول الرقمي 2030",
      },
      footer: {
        title: "التقنية الرقمية لإدارة أعمال المحاماة",
        allRightsReservedToLegit: " @ جميع الحقوق محفوظة ل مستند 2023",
        privacyPolicy: "سياسة الخصوصية",
        termsAndConditions: "الشروط والأحكام",
      },
      whatAboutMustand: {
        title: 'ما هو مـسـتند؟',
        description1: 'برنامج إلكتروني لإدارة مكاتب وشركات المحاماة والإدارات القانونية داخل الشركات الكبرى، وإدارة ما ',
        description2: 'يتعلق بالدعاوى وإجراءات التقاضي',
        description3: 'حيث يتيح لهم:',
        point1: 'متابعة الأنشطة والمهام اليومية',
        point2: ' تفعيل التنبيهات والمواعيد الخاصة بالجلسات ',
        point3: 'ربط التنبيهات داخل النظام لتحقيق الكفاءة وزيادة الإنتاجية في العمل',
        point4: 'يمكن الوصول إلى البيانات من خلال الهواتف المحمولة أو الأجهزة اللوحية أو أجهزة الكمبيوتر على أنظمة Android  و iOS و Windows',
        point5: 'نظام مرن لإدارة العملاء وقضاياهم بسرية ',
        point6: 'إمكانية محاسبة العملاء وإدارة الفواتير الإلكترونية من حيث إصدارها وتوافقها مع متطلبات هيئة الزكاة ',
        point7: 'يمكن للعملاء الإطلاع على تحديثات القضية وقرارات المحكمة ',
      }
    },
    navbar: {
      dashboard: "الرئيسية",
      clients: "العملاء",
      cases: "القضايا",
      users: "إدارة المستخدمين",
      revisions: "المراجعات",
      contracts: "العقود",
      invoices: "الفواتير",
      settings: "الإعدادات",
      firmSettings: "بيانات الشركة",
      generalSettings: "إعدادات عامة",
      calculations: "إدارة الحسابات",
      createInvoices: "إضافة فاتورة",
      editInvoices: "تعديل فاتورة",
      showInvoices: "عرض الفاتورة",
      addCase: "إضافة قضية",
      legalLibrary: "المكتبة القانونية",
    },
    roles: {
      firm_manager: "مدير المكتب",
      secretary: "سكرتير",
      consultant: "مستشار قانونى",
      lawyer: "محامي",
      coordinator: "ممارس قانوني",
      case_manager: "مدير القضايا",
      accountant: "مدير حسابات",
      client: "عميل",
    },
    errors: {
      errorOccurred: "حدث خطأ ما!",
      invalidSubdomain: "ساب دومين غير صالح",
      invalidEmail: "عنوان بريد إلكتروني غير صحيح",
      required: "حقل إلزامي",
      passwordMismatch: "كلمة السر غير متطابقة",
      invalidImageType: "نوع الصورة غير صحيح",
      invalidFileType: "نوع الملف غير صحيح",
      invalidImageSize: "حجم الصورة أكبر من 2 ميغا بايت",
      invalidPhoneFormat: "رقم الجوال غير صحيح",
      invalidVerificationCode: "رمز التأكيد غير صحيح",
      invalidVerificationCode2: "كود التحقق منتهى الصلاحية. الرجاء  استخدام كود جديد.",
      passwordMinLength: "كلمة السر يجب ألا تقل عن 8 حروف",
      passwordMaxLength: "كلمة السر يجب ألا تزيد عن 128 حروف",
      logoUploadFailed: "لم يتم رفع الصورة",
      noImageFound: "لا يوجد صورة",
      onlyNumbers: "يجب أن تكتب أرقام فقط",
      maxLength250: "يجب ألا يتعدى 250 حرف",
      maxFileSize: "يجب ألا يتعدى حجم الملف  2MB",
      maxLength10: "يجب أن يكون 10 أرقاما",
      maxLength15: "يجب أن يكون 15 رقما",
      maxLength12: "يجب أن يكون 12 رقما",
      maxLength75: "يجب ألا يتعدى 75 حرف",
      maxLength100: "يجب ألا يتعدى 100 حرف",
      maxLength150: "يجب ألا يتعدى 150 حرف",
      maxLength300: "يجب ألا يتعدى 300 حرف",
      maxLength500: "يجب ألا يتعدى 500 حرف",
      maxLength50: "يجب ألا يتعدى 50 حرف",
      maxLength1500: "يجب ألا يتعدى 1500 حرف",
      maxLength1000: "يجب ألا يتعدى 1000 حرف",
      maxLength5000: "يجب ألا يتعدى 5000 حرف",
      maxLength10000: "يجب ألا يتعدى 10000 حرف",
      maxNumbers3: "يجب ألا يتعدى 5 أرقام",
      maxNumbers5: "يجب ألا يتعدى 3 أرقام",
      from16To34: "يجب الا يقل عن 16 ولا يتعدى 34 حرفا",
      onlyArabic: "حروف عربية فقط",
      fileType: "نوع الملف غير صحيح",
      expiredLink:
        "عذرا ، هذا الرابط انتهى. إعادة التوجيه إلى لصفحة تسجيل الدخول في 3 ثوان",
      networkError: "لا يوجد اتصال بالإنترنت",
      imageRestrictions:
        "أكبر حجم: 2 ميغا بايت. الأنواع المسموح بها: png, jpeg, jpg",
      fileRestrictions:
        "الأنواع المسموح بها: png, jpeg, jpg, doc, docx, xlsx, xls, pdf",
        excelFileAccepted:
        "الأنواع المسموح بها: xlsx, xls",
      fileRestrictionSize: "الحد الأقصى للمساحة ٢ ميجا",
      isInvalid: "غير مقبول",
      minEndDate: "تاريخ النهاية لا يجب أن يكون أصغر من تاريخ البداية",
      minEndTime: "وقت النهاية يجب أن يكون أكبر من وقت البداية",
      lastTime: "غير مسموح بأضافه وقت ماضى",
      minFutureDate: "يجب أن يكون تاريخ قبل أو يساوي تاريخ اليوم",
      SubdomainAlreadyInUse: "هذا ال ساب دومين مستخدم من قبل",
      emailAlreadyInUse: "هذا البريد الإلكتروني مستخدم من قبل",
      accessDenied: "لا يمكنك الذهاب لهذه الصفحة",
      notfound:
        "عفوا، البريد الذي أدخلته غير مسجل. برجاء التحقق و إعادة المحاولة",
      suspended: "الحساب متوقف، تواصل مع فريق الدعم للمساعدة",
      expired: "الحساب منتهي الصلاحية، تواصل مع فريق الدعم لإعادة إرسال الدعوة",
      inactive: "الحساب غير مفعل تواصل مع فريق الدعم للمساعدة",
      unauthenticated: "انتهت صلاحية الجلسة",
      atLeastOneFieldISRequired: "مطلوب حقل واحد على الأقل",
      dicisonDateErrorMsg: "تاريخ القرار لا يمكن أن يسبق التاريخ:  {date}",
      historyDateErrorMsg:
        "التاريخ يجب أن يسبق تاريخ القرارات المدخلة:  {date}",
      minCaseStartDate: "يجب أن يكون التاريخ بعد تاريخ ورود القضية :  {date}",
      minOneFieldItems: "أضف على الأقل عنصرا واحد",
      min0max100: "يجب أن تدخل قيمة من 0 إلى100",
      afterInvoiceData: "يجب أن يكون بعد تاريخ الفاتورة",
      enterInvoiceData: "يجب أن تدخل تاريخ الفاتورة أولا",
      afterInvoiceDataFilter: "يجب أن تدخل تاريخ الاستحقاق بعد تاريخ الفاتورة",
      min0max100: "يجب أن تدخل قيمة من 0 إلى100",
      mustBeANumber: "يجب أن تدخل أرقام",
      maxNineNine: "أقصى قيمة هي 999999999",
      maxFiveNine: "أقصى قيمة هي 99999",
      moreThanZero: "يجب أن يكون أكبر من 0",
      invalidSubdomain: "نطاق غير صالح",
      mustBeExactly6DigitsInEnglish: "يجب أن تكون 6 أرقاما بالانجليزية",
      youCantRichThisPage: "لا يمكن الوصول لهذة الصفحة",
      specialChars: "مسموح فقط بالاحرف والارقام",
      alphaNumInEnglishOnly: "برجاء ادخال ارقام واحرف انجليزية فقط",
      alphaNumInArabicOnly: "برجاء ادخال ارقام واحرف عربية فقط",
      notAllowArabicAndSpaces: "برجاء ادخال الأحرف و الرموز بالإنجليزية فقط",
      invalidPhoneNumber: "رقم الجوال غير صحيح",
      englishCharOnly: "برجاء إدخال حروف إنجليزية فقط",
      numbersOnly: "برجاء إدخال ارقام فقط",
      arabicCharOnly: "برجاء إدخال حروف عربية فقط",
      charWithNum: "برجاء إدخال حروف و أرقام",
      minOneField: "يرجي تحديد عامل تصفيه واحد على الأقل",
      arabicOnly: "اللغة العربية فقط",
      verdictNoValidation:
        "رقم الصك إلزامي بما لا يقل عن ٦ أرقام ولا يزيد عن ٢٠ رقم",
      ibanErr: "يرجى ادخال رقم الآيبان صحيح",
      pastTime: "يجب ادخال وقت قادم",
      enterEndDate: 'يجب أدخال تاريخ الانتهاء',
      enterEndTime: 'يجب أدخال موعد الانتهاء',
      min10max200: "يجب الا يقل عن 10 ولا يتعدى 200",
      errorInRowNumber: 'يجب تعديل هذه البيانات فى الصف رقم'
    },
    controls: {
      emailAddress: "البريد الإلكتروني",
      password: "كلمة المرور",
      confirmPassword: "تأكيد كلمة المرور",
      phoneNumber: "رقم الجوال",
      verificationCode: "رمز التأكيد",
      legalFirmName: "اسم المكتب",
      firmName: "اسم الشركة",
      adminEmail: "ايميل صاحب المكتب",
      subdomain: "ساب دومين",
      nationality: "الجنسية",
      identityType: "نوع الهوية",
      identityDocType: "نوع وثيقة الهوية",
      residenceCountry: "دولة الإقامة",
      residenceCity: "مدينة الاقامة",
      identifyNum: "رقم الهوية",
      identifyDocNum: "رقم وثيقة الهوية",
      notes: "ملاحظات",
      leaveMsg: "اترك الرسالة",
      rememberMe: "تذكر كلمة المرور",
      clientName: "اسم العميل",
      clientNamePlaceholder: "اختر اسم العميل",
      searchbyClientName: "أبحت باسم العميل",
      companyName: "اسم الشركة",
      companyAddress: "عنوان الشركة",
      companyAddressPlaceholder: "اكتب هنا عنوان الشركة",
      birthDate: "تاريخ الميلاد",
      caseID: "الرقم المرجعي للقضية",
      court: "محكمة",
      caseDate: "تاريخ القضية",
      circleNameNum: "اسم ورقم الدائرة",
      circleName: "رقم الدائرة",
      circle: "الدائرة",
      address: "العنوان",
      country: "الدولة",
      city: "المدينة",
      caseName: "اسم القضية",
      category: "التصنيف",
      type: "النوع",
      caseSummary: "ملخص القضية",
      twitter: "تويتر",
      facebook: "فيسبوك",
      socialNetworks: "وسائل التواصل الاجتماعي",
      attachments: "مرفقات",
      authorization: "توكيل",
      caseDetails: "تفاصيل القضية",
      courtDegree: "درجة المحكمة",
      courtName: "اسم المحكمة",
      name: "الاسم",
      brief: "نبذة",
      role: "الدور",
      jobTitle: "المسمى الوظيفي",
      jobTitleEn: "المسمى بالانجليزية",
      jobTitleAr: "المسمى بالعربية",
      username: "اسم مالك الموسسة",
      employeeName: "اسم الموظف",
      createdBy: "انشئ من قبل",
      addedAt: "تمت اضافته",
      dueDate: "الموعد النهائي",
      title: "العنوان",
      description: "وصف",
      userStatus: "حالة المستخدم",
      startDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      attendees: "الحضور",
      guests: "المدعوين",
      eventLocation: "مكان الحدث",
      locationDetails: "تفاصيل المكان",
      reminder: "تنبيه",
      sendReminderVia: "إرسال التنبيه على",
      interval: "فترة",
      assignee: "المكلف",
      eventStatus: "حالة الحدث",
      eventDate: "تاريخ الحدث",
      status: "الحالة",
      statusPlaceholder: "اختر حالة الفاتورة",
      dateOfCase: "تاريخ حالة القضية",
      start_date_case: "تاريخ القضية",
      start_date: "بداية التاريخ",
      end_date: "نهاية التاريخ",
      case_status: "حالة القضية",
      the_court: "المحكمة",
      client: "العميل",
      hisChar: "صفته",
      clients: "العملاء",
      sort: "ترتيب",
      filter: "تنقيه",
      decisionType: "نوع القرار",
      decisionDate: "تاريخ القرار",
      decisionSummary: "ملخص القرار",
      decisionStatment: "منطوق القرار",
      details: "التفاصيل",
      yourName: "اسمك",
      date: "التاريخ",
      time: "الوقت",
      hijri: "هجري",
      georgian: "ميلادي",
      play: "تشغيل",
      stop: "إيقاف",
      sms: "الرسائل النصية",
      commercialRegistrationNo: "رقم السجل التجاري",
      commercialRegistrationNoCompany: "رقم السجل التجاري للشركة",
      vatRegistrationNo: "رقم التسجيل في ضريبة القيمة المضافة",
      vatRegistrationNoCompany: "رقم التسجيل في ضريبة القيمة المضافة للشركة",
      clientType: "نوع العميل",
      from: "من",
      to: "إلى",
      services: "الخدمات",
      invoiceNumber: "رقم الفاتورة",
      service: "الخدمة",
      servicePlaceholder: "قم باختيار الخدمة القانونية",
      datePlaceholder: "يوم / شهر / سنة",
      num: "العدد",
      clientKind: "صفة العميل",
      najizCaseDate: "تاريخ القضية بناجز",
      caseManager: "المكلف بالقضية",
      najizCaseNo: "رقم القضية بناجز",
      orderNo: "رقم الطلب",
      archiveNo: "رقم الأرشيف",
      caseSubject: "موضوع الدعوى",
      caseRequests: "طلبات الدعوى",
      caseEvidence: "أسانيد الدعوى",
      caseMainClassification: "تصنيف الدعوى الرئيسي",
      caseSubClassification: "تصنيف الدعوى الفرعي",
      caseClassification: "نوع الدعوى",
      najezDate: "تاريخ القضية بناجز",
      Opponent: "الخصم",
      partyType: "صفة طرف الدعوى",
      job: "الوظيفة",
      relationDegree: "درجة العلاقة",
      contactName: "اسم مسؤول التواصل",
      companyType: "نوع الشركه",
      taskName: "اسم المهمة",
      theDescription: "الوصف",
      assigneeEmployee: "الموظف المكلف بالمهمة",
      startTask: "موعد بدء المهمة",
      endTask: "موعد إنهاء المهمة",
      seesionType: "نوع الجلسة",
      invitedTeamMember: "فريق العمل المدعويين",
      invitedParties: "اطراف الدعوى المدعوين",
      invitedContacts: "جهات الاتصال المدعوين",
      place: "المكان",
      describePlace: "وصف المكان",
      reminderDate: "موعد التنبيه",
      placeDetails: "تفاصيل المكان",
      dataAndTime: "التاريخ و الموعد",
      reminders: "التنبيهات",
      automaticTasks: "مهام الجلسة",
      actNo: "رقم الصك",
      actFile: "ملف الصك",
      verdictDate: "تاريخ إصدار الحكم",
      verdictCharacteristic: "صفة الحكم",
      result: "النتيجة",
      nextSessionDate: "تاريخ الجلسة القادمة",
      report: "التقرير",
      bankName: "اسم البنك",
      iban: "رقم الآيبان",
      appointmentName: 'اسم الموعد',
      relatedCase: 'القضايا المرتبطة',
      actionDate: 'تاريخ الإجراء',
      actionTime: 'موعد الإجراء',
      clientStauts: 'حالة العميل',
      pendingPayments: 'المطالبات',
      completeName: 'الإسم بالكامل',
      chooseIdentityType: 'اختر طريقة اثبات الهوية',
      nabourhood: 'الحي',
      stayCountry: 'دولة الإقامة',
      stayCity: 'مدينة الاقامة',
      brand: 'الماركة',

    },
    placeholders: {
      phoneNumber: "ادخل رقم الجوال",
      phoneNumber2: "الجوال",
      date: "قم بتحديد الفترة الزمنية",
      invoiceNumber: "ابحث برقم الفاتوره",
      services: "قم بالتصفية بأنواع الخدمات",
      invoiceStatus: "قم بالتصفية بحالة الفاتورة",
      clients: "قم بالتصفية بالعملاء",
      address: "عنوان الشركة",
      clientAddress: "عنوان العميل",
      country: "اختر الدولة",
      city: "ادخل المدينة",
      client: "اختر العميل",
      caseAdmin: "اختر المكلف بالقضية",
      Opponent: "اختر اسم الخصم",
      caseMainClassification: "اختر التصنيف الرئيسي",
      caseSubClassification: "اختر التصنيف الفرعي",
      caseClassification: "اختر نوع الدعوى",
      courtDegree: "اختر درجة المحكمة",
      caseStatus: "اختر حالة القضية",
      najzDate: "قم بتحديد الفترة الزمنية",
      partyName: "اسم طرف الدعوى",
      email: "ادخل البريد الإلكتروني",
      identityName: "بطاقة او جواز او هوية",
      identityNo: "رقم الهوية",
      partyCharacter: "ادخل صفة طرف الدعوى",
      enterContactName: "ادخل اسم جهة الاتصال",
      enterCaseRelation: "اختر درجة العلاقة",
      enterJob: "ادخل الوظيفة",
      enterRelation: " ادخل درجة العلاقة",
      enterClientName: "ادخل اسم العميل",
      enterContactperson: "ادخل اسم مسؤول التواصل",
      chooseIdentityType: "اختر نوع وثيقة الهوية",
      enterIdentityNum: "ادخل رقم وثيقة الهوية",
      chooseNationalitiy: "اختر الجنسية",
      enterCompanyName: "ادخل اسم الشركة",
      enterCompanyType: "اختر نوع الشركة",
      enterNotes: "قم بإدخال ملاحظاتك",
      enterTaskName: "ادخل اسم المهمة",
      taskDescription: "وصف المهمة بإيجاز",
      chooseEmployee: "اختر الموظف",
      taskStartDate: "قم بتحديد تاريخ بدء المهمة",
      taskEndDate: "قم بتحديد تاريخ انهاء المهمة",
      chooseTaskCategory: "اختر تصنيف المهمة",
      enterCategory: "ادخل التصنيف",
      chooseCase: "اختر القضية",
      addNotesHere: "دوًن ملاحظاتك هنا",
      chooseSessionType: "اختر نوع الجلسة",
      chooseInvitedTeamMember: "اختر فريق العمل المدعويين",
      chooseInvitedParties: "اختر اطراف الدعوى المدعوين",
      chooseInvitedContacts: "اختر جهات الاتصال المدعوين",
      sessionDate: "قم بتحديد تاريخ الجلسة",
      reminderDate: "قم بتحديد تاريخ التنبيه",
      onlineOrIndoor: "اونلاين ام في المقر",
      descripePlace: "وصف المكان بإيجاز",
      verdictDate: "قم بتحديد تاريخ اصدار الحكم",
      verdictCharacteristic: "اختر صفة الحكم",
      defineDate: "قم بتحديد التاريخ",
      relatedCase: "اختر القضايا المرتبطة بهذا الموعد",
      chooseInvitedClients: "اختر الحضور من العملاء",
      chooseTeamMembers: "اختر الحضور من فريق العمل",
      appointmentDate: "قم بتحديد تاريخ الموعد",
      userStatus: "حالة المستخدم",
      startTime: "موعد البدء",
      endTime: "موعد الانتهاء",
      chooseClientStauts: "أختر حالة العميل",
      chooseClientType: 'اختر نوع العميل',
      choosePendingPayment: 'أختر المطالبات',
      chooseNabourhood: 'أختر الحي',
      searchByContractorParty: "ابحث باسم العقد او اسم الطرف الآخر",
      searchByContractorPartyOrClient: " ابحث باسم العقد او اسم الطرف الآخر او اسم العميل",
      chooseAssigneeLawyer: 'اختر اسم المحامي المكلف',
      chooseContractType:"اختر نوع العقد",
    },
    login: {
      signIn: "تسجيل الدخول",
      welcomeTo: "مرحبا بك في",
      professionalDashboardForLaw: "لوحة القيادة المهنية للقانون",
      findThousandCases:
        "منصة إلكترونية متجددة لإدارة أعمال مكاتب المحاماة وتنظيم المهام وإدارة القضايا.",
      didntRecieveCode: "لم يصلك الكود ؟",
      resendCode: "ارسل الكود مرة اخرى",
      setPassword: "تعيين كلمة مرور",
      uploadLogo: "ارفع لوجو الشركة",
      forgotPassword: "نسيت كلمة المرور ؟",
    },
    resetPassword: {
      reassignPassword: "إعادة تعيين كلمة المرور",
      emailSent: "تم إرسال رسالة بريدية بنجاح",
      emailSentMsg: "تم إرسال رسالة بريدية بالبيانات الخاصة بتغيير كلمة المرور",
    },
    dashboard: {
      title: "مؤشرات الأداء",
      teamMembers: "فريق العمل",
      closedCases: "القضايا المنتهية",
      incomingCases: "القضايا الواردة",
      clients: "العملاء",
      court_degree_title: "توزيع القضايا حسب درجة المحكمة",
      court_degree_hint: "القضايا الجاري العمل عليها موزعة حسب درجة المحكمة",
      cases: "القضايا",
      opened: "مفتوحة",
      closed: "منتهية",
      Calender: 'التقويم',
      PerformanceMatrics: "مؤشرات الاداء",
      RecentUpdates: "آخر التحديثات",
      viewAllTasks: "عرض جميع المهام",
      allTasks: 'جميع المهام',
      appointment: 'موعد',
      tasks: "مهام",
      getInvoice: 'استحقاق فاتورة',
      allEvents: "كل الاحداث",
      sessions: "الجلسات",
      appointments: "المواعيد",
      invoices:"الفواتير المستحقة",
      dateTime: "التوقيت و المكان",
      appointmentData: "بيانات الموعد",
      viewAll: 'كل التقويم',
      viewMy: "تقويمى",
      appointmentDetails: "تفاصيل الموعد",
      online: 'أونلاين',
      indoor: "مقر الشركة",
      relatedCaseToAppointment: 'القضايا المرتبطة بالموعد',
      deleteAppointmentPopupTitle: "تأكيد حذف موعد",
      deleteAppointmentConfirmMsg1: "هل تريد حذف الموعد التالي؟",
      deleteAppointmentConfirmMsg2: "عند التأكيد سيتم حذف الموعد بشكل نهائي",
      startDate: 'موعد البدء',
      tasksRecentlyAdded: 'المهام المسندة حديثاً',
      noTasksRecentlyAdded: "لا توجد مهام مسندة إليك",
      dueInvoice: "الفواتير المستحقة",
      noInvoices:"لا توجد فواتير مستحقة",
      displayAllInvoices: "عرض جميع الفواتير",
      messages: 'المراسلات',
      emptyMessages1: "لا توجد مراسلات جديدة",
      emptyMessages2: 'يمكنك دائماَ تفقد قائمة المراسلات الخاصة بكل قضية في تفاصيل القضية ',
      recentActivity: 'شوهد مؤخراَ',
      recentViewEmpty: 'ستظهر هنا آخر الوجهات التي قمت بزيارتها',
      issuedCases: 'القضايا الواردة',
      activeClient: 'العملاء الحاليين',
      paiedInvoice: 'الفواتير المسددة',
      completeTasks: 'المهام المكتملة',
      caseClass: 'تصنيفات القضايا',
      verdicts: 'الأحكام',
      caseCount: 'عدد القضايا التي حكم بها',
      case: 'قضية',
      sessionNumber: 'عدد الجلسات',
      sessions: 'الجلسات',
      currentMonthTask: 'مهام الشهر الجاري',
      firstWeek: 'الأسبوع الأول',
      secondWeek: 'الأسبوع الثانى',
      thirdWeek: 'الأسبوع الثالث',
      fourthWeek: 'الأسبوع الرابع',
      completed: 'مكتملة',
      opened: 'جارية',
      delayed: 'متأخرة',
      clients: 'العملاء',
      currentYear: 'هذا العام',
      lastYear: 'العام الماضي',
      totalClients: 'إجمالي العملاء',
    },
    attachments: {
      caseFiles: "ملفات القضية",
      searchInFile: "ابحث باسم الملف او المجلد",
      arrangeBy: "ترتيب حسب",
      files: "الملفات",
      uploadFile: "تحميل ملف",
      createFolder: "إنشاء مجلد",
      theFolders: "المجلدات",
      folders: "مجلدات",
      theFiles: "الملفات",
      filess: "ملفات",
      uploadedFiles: "الملفات الملحقة",
      createNewFolder: "إضافة مجلد جديد",
      uploadAllFiles: "تحميل كل الملفات",
      uploadNewFiles: "تحميل مرفقات جديدة",
      addFolder: "إضافة مجلد",
      folderName: "إسم المجلد",
      deleteFile: "تأكيد حذف الملف",
      deleteFolder: "تأكيد حذف المجلد",
      noFoldersFound: "لا توجد مجلدات",
      noFilesFound: "لا توجد ملفات",
      moveToFolder: "نقل إلى مجلد",
      newName: "الاسم الجديد",
      deleteMsgFolder1: "هل تريد حذف المجلد التالي من مرفقات القضية؟",
      deleteMsgFolder2:
        "عند تأكيد الحذف، سيتم حذف المجلد و جميع الملفات التي يحتويها بشكل دائم",
      deleteMsgFile1: "هل تريد حذف الملف التالي من مرفقات القضية؟",
      deleteMsgFile2: "عند تأكيد الحذف، سيتم حذف الملف بشكل دائم",
    },
    cases: {
      addNewClient: "أضف عميل جديد",
      company: "الشركة",
      infoAboutCase: "تفاصيل خاصة بالقضية",
      infoAboutInvitation: "تفاصيل خاصة بالدعوى",
      infoAboutClient: "تفاصيل خاصة بالعميل",
      editCaseInfo: "تعديل بيانات القضية",
      deleteCase: "تأكيد حذف القضية",
      newCase: "قضية جديدة",
      editCase: "تعديل قضية",
      clientInfo: "بيانات العميل",
      caseInfo: "بيانات القضية",
      attachments: "المرفقات",
      caseHistory: "تاريخ سير القضية",
      caseHistoryFollow: "متابعة سير الدعوى",
      suitParties: "أطراف الدعوى",
      teamMembers: "فريق العمل",
      contacts: "جهات الاتصال",
      caseTeam: "فريق القضية",
      allUsers: "كل المستخدمين",
      createNewCase: "إنشاء قضية جديدة",
      claimantsList: "قائمة المدعين",
      defendantsList: "قائمة المدعى عليهم",
      partiesList: "قائمة اطراف الدعوى",
      plaintiff: "المدعي",
      defendant: "المدعى عليه",
      plaintiffLookup: "مدعي",
      defendantLookup: "مدعى عليه",
      suitPartyType: "صفة طرف الدعوى",
      partyType: "نوع طرف الدعوى",
      client: "عميل",
      defendantType: "خصم",
      plaintiffType: "موكل",
      chooseClientType: "تحديد نوع العميل",
      chooseContactType: "تحديد نوع العميل",
      choosePartyType: "تحديد نوع طرف الدعوى",
      people: "الأفراد",
      companies: "الشركات",
      companyInfo: "بيانات الشركة",
      communicationRep: "مسئول التواصل",
      previousCaseHistory: "المحل القضائي السابقة",
      suitSubject: "موضوع الدعوى",
      suitRequests: "طلبات الدعوى",
      suitGrounds: "أسانيد الدعوى",
      appointments: "المواعيد",
      upcomingAppointments: "المواعيد القادمة",
      todayAppointment: "المواعيد الحالية",
      finishedAppointments: "المواعيد المنتهية",
      tasksList: "قائمة الاعمال",
      currentTasks: "المهام الحالية",
      todayTasks: "مهام اليوم",
      lateTasks: "المهام المتأخره",
      completedTasks: "المهام المكتملة",
      casesSummary: "ملخص القضايا",
      allCases: "كل القضايا",
      case: "{count, plural, =0 {# قضية} one {# قضية} other {# قضية}}",
      noCasesFound: "لا يوجد قضايا",
      generalInfo: "معلومات عامة",
      noCaseHistory: "لا يوجد تاريخ سير القضية",
      addCaseHistory: "إضافة سيرة القضية",
      editCaseHistory: "تعديل سيرة القضية",
      caseHistoryNo:
        "{count, plural, =0 {# سيرة القضية} one {# سيرة القضية} other {# سيرة القضية}}",
      addToCaseParty: "اضف طرف دعوى",
      editCaseParty: "تعديل طرف دعوى",
      caseParties: "أطراف الدعوى",
      casePartiesCount:
        "{count, plural, =0 {# شخص} one {# شخص} other {# اطراف الدعوى}}",
      identityType: "التعريف",
      passport: "جواز سفر",
      residency: "رقم الإقامة",
      identity: "الهوية الوطنية",
      country: "الدولة",
      name: "الاسم",
      email: "البريد الإلكتروني",
      notes: "ملاحظات",
      clientNDiscount: "موكل/خصم",
      noPartiesFound: "لا يوجد اطراف دعوى",
      noCommentsFound: "لا توجد  تعليقات",
      noContactsFound: "لا توجد جهة اتصال",
      noFirmsFound: "لا توجد شركات",
      name: "الاسم",
      email: "البريد الإلكتروني",
      related: "جهة الاتصال",
      relation: "درجة العلاقة",
      birthday: "تاريخ الميلاد",
      phoneNumber: "رقم الجوال",
      contactsTitle: "جهات الاتصال",
      caseContactsCount:
        "{count, plural, =0 {# جهة اتصال} one {# جهة اتصال} other {#  جهة اتصال}}",
      addContact: "إضافة جهة اتصال",
      contactInfo: "بيانات جهة الاتصال",
      editContact: "تعديل جهة اتصال",
      addRelation: "إضافة درجة علاقة جديدة",
      caseFilter: "تحديد القضية",
      team_members: "أعضاء فريق",
      comments: "التعليقات",
      related_cases: "قضايا مرتبطة",
      addCaseRelation: "إضافة قضية مرتبطة",
      deleteCaseRelation: "حذف القضية المتعلقة",
      caseNo: "رقم القضية",
      arrangeNo: "رقم النظام",
      address: "العنوان",
      status: "الحالة",
      notes: "ملاحظات",
      court_degree: "درجة المحكمة",
      noRelationsFound: "لا يوجد قضايا مرتبطة",
      najizCaseNo: "رقم القضية بناجز",
      caseName: "اسم القضية",
      client: "العميل",
      assignedLawyer: "المكلف بالقضية",
      najizCaseDate: "تاريخ القضية بناجز",
      caseDate: "تاريخ القضية",
      caseType: "نوع القضية",
      status: "الحالة",
      actions: "الإجراءات",
      casesStatus: {
        new: "جديدة",
        transferred: "منقولة",
        inprogress: "جارية",
        closed: "مقفلة",
      },
      case: "قضية",
      caseDetails: "بيانات الدعوى",
      courtDetails: "بيانات المحكمة",
      createdBy: "منشأ من قبل",
      caseKind: "نوع القضية / الدعوى",
      caseArchiveNo: "رقم القضية بالأرشيف",
      caseNoInCourt: "رقم القضية بالمحكمة",
      generalInfo: "بيانات عامة",
      sessions: "الجلسات",
      contactListEmptytext1: "لم تقم بإضافة اي جهة اتصال",
      contactListEmptytext2: "قم بإضافة جهات الاتصال المرتبطين بهذة القضية",
      noRelatedCase: "لا يوجد قضايا مرتبطة",
      deleteRelationPopupTitle: "تأكيد حذف قضية مرتبطة",
      deleteRelationConfirmMsg1:
        "هل تريد حذف القضية التالية من القضايا المرتبطة؟",
      addReminder: "إضافة تنبيه",
      editReminder: "تعديل تنبيه",
      noReminder: "لم يتم إضافة تنبيهات",
      verdicts: "الأحكام",
      export: "طباعة",
      exportToExcel: "طباعة كملف اكسل",
      exportToPdf: "طباعة ك pdf",
      exportCaseSummary: "طباعة ملخص القضية",
      exportCaseDetails: 'طباعة تقرير تفصيلي للقضية',
      partyType: 'تصنيف طرف الدعوى'
    },
    sessions: {
      sessionsNo: "{count, plural, =0 {# جلسات} one {# جلسات} other {# جلسات}}",
      theSessions: "الجلسات",
      sessions: "جلسات",
      addSession: "إضافة جلسة",
      orderBy: "ترتيب حسب",
      sesssionStatus: "حالة الجلسة",
      noSessions: "لا يوجد جلسات",
      sessionCategory: "تصنيف الجلسة",
      attendees: "الحضور",
      sessionReport: "تقرير الجلسة",
      createdBy: "تم الإنشاء بواسطة",
      noSessionResult: "لم يتم إضافة نتيجة الجلسة",
      addSessionResult: "إضافة نتيجة الجلسة",
      editSessionResult: "تعديل نتيجة الجلسة",
      addSessionReport: "إعداد تقرير الجلسة",
      noSessionReport: "لم يتم كتابة تقرير الجلسة",
      sessionResult: "نتيجة الجلسة",
      rescheduleNextSession: "موعد الجلسة القادمة",
      emptyText1: "لم تقم باضافة اي جلسة",
      emptyText2: "قم بإضافة جلسات القضية",
      deleteConfirmMsg1: "هل تريد حذف الجلسة التالية من جلسات القضية؟",
      deleteConfirmMsg2:
        "عند تأكيد الحذف، سيتم إلغاء الجلسة بشكل دائم، وسيتم إرسال تنويه بالالغاء للمشاركين",
      deletePopupTitle: "تأكيد حذف جلسة",
      location: "المكان",
      dateTime: "الموعد",
      verdictAct: "صك الحكم",
      actNo: "رقم الصك",
      verdictDate: "تاريخ إصدار الحكم",
      pleaseAddSessionResult: "الرجاء إضافة نتيجة الجلسة أولا",
      sessionReport: "تقرير الجلسة",
      reportStatus: {
        approved: "تم مراجعة التقرير",
        pending: "لم يتم مراجعة التقرير",
      },
      reportApproved: "تم مراجعة التقرير و إعتماده",
      approveReportConfirmMsg1: "هل تريد إرسال تقرير الجلسة التالية للعميل:",
      approveReportConfirmMsg2:
        "عند التأكيد سيتم إرسال التقرير تلقائيا لعملاء هذه القضية",
      approveReportPopupTitle: "تأكيد إرسال تقرير الجلسة",
      sessionResult: "نتيجة الجلسة",
      addHistoryMsg: "الرجاء إضافة سير الدعوى أولا",
      addSessionHistoryAndResult: "الرجاء إضافة سير الدعوى و نتيجة الجلسة أولا",
    },
    verdicts: {
      verdictNo: "{count, plural, =0 {# حكم} one {# حكم} other {# حكم}}",
      verdicts: "الأحكام",
      verdict: "حكم",
      addVerdict: "إضافة حكم",
      editVerdict: "تعديل حكم",
      verdictAct: "صك الحكم",
      verdictInfo: "بيانات الحكم",
      verdictChar: "صفة الحكم",
      verdictDate: "تاريخ الحكم",
      actNo: "رقم الصك",
      courtName: "اسم المحكمة",
      courtDegree: "درجة المحكمة",
      circleNo: "رقم الدائرة",
      noVerdictAct: "لا توجد صكوك",
      emptyText1: "لم تقم باضافة اي أحكام",
      emptyText2: "قم بإضافة أحكام القضية",
      deletePopupTitle: "تأكيد حذف حكم",
      deleteConfirmMsg1: "هل تريد حذف الحكم التالي من احكام القضية؟",
      deleteConfirmMsg2: "عند التأكيد سيتم حذف الحكم بشكل نهائي",
    },
    parties: {
      plaintiff: "مدعى",
      defendants: "مدعى عليه",
      plaintiffs: "المدعيين",
      defendants: "المدعى عليهم",
      agent: "وكيل",
      opponent: "الخصم",
      partyType: "صفة طرف الدعوى",
      partyInfo: "بيانات طرف الدعوى",
      addParty: "إضافة طرف دعوى",
      editParty: "تعديل طرف دعوى",
      deletePartyPopupTitle: "تأكيد حذف طرف دعوى",
      deleteConfirmMsg1: "هل تريد حذف طرف الدعوى التالي من معلومات القضية؟",
      deleteConfirmMsg2:
        "عند تأكيد الحذف، لن تتمكن من الوصول للمعلومات المحذوفة مرة أخرى",
      partyCharacterTitle: "نوع طرف الدعوى",
      noDefendants: "لم تقم بإضافة اي مدعى عليهم",
      noPlaintiffs: "لم تقم بإضافة اي مدعيين",
    },
    contacts: {
      deleteContactPopupTitle: "تأكيد حذف جهة إتصال",
      deleteConfirmMsg1:
        "هل تريد حذف جهة الإتصال التالية من جهات إتصال القضية؟",
      deleteConfirmMsg2:
        "عند تأكيد الحذف، يتم إزالة بيانات جهة الإتصال بشكل دائم",
      relationDegree: "درجة العلاقة",
      phoneNumber: "رقم الهاتف",
    },
    tasks: {
      orderBy: "ترتيب حسب",
      taskStatus: "حالة المهمة",
      tasks: "المهام",
      filter: "التصفية",
      task: "{count, plural, =0 {# مهام} one {# مهام} other {# مهام}}",
      newAssigneeWarning:
        "سيتم إضافة  المستخدم لفريق القضية  ليمكنه الإطلاع على تفاصيل القضية",
      createTask: "إضافة مهمة",
      editTask: "تعديل مهمة",
      current: "الحالية",
      completed: "مكتمل",
      overdue: "المتأخرين",
      noDue: "لا يوجد موعد نهائي",
      noAssignee: "غير معين",
      assignedTo: "معين إلى",
      noTasksFound: "لا يوجد مهام",
      completedAt: "تاريخ الاكتمال",
      completedBy: "تم الاكمال بواسطة",
      changedTaskDateWarning:
        "تم تعديل الموعد النهائي للمهمة, نوصي بمراجعة إعدادات التنبيهات",
      tasksNotCompletedYet: "اكمل {complete} من {total} مهام",
      shareTasks: "{count} مهام لم تكتمل بعد",
      taskInfo: "بيانات المهمة",
      reminders: "التنبيهات",
      attachments: "المرفقات",
      remindersMsg:
        " سيتم تذكير المشاركين في المهمة بشكل تلقائي في المواعيد الآتية",
      addCategory: "أضافة تصنيف جديد",
      dueDate: "الموعد النهائي",
      taskDescription: "وصف المهمة",
      taskCategory: "التصنيف",
      assigneeName: "اسم الموظف",
      createdBy: "تمت الإضافة بواسطة",
      createdAt: "تاريخ الإضافة",
      startTaskTime: "موعد بدء المهمة",
      dueTaskTime: "الموعد النهائي للمهمة",
      addREminder: "إضافــة تنبيه",
      noAttachments: "لا توجد مرفقات",
      ascDueDate: "الأحدث بالاستحقاق",
      desDueDate: "الأقدم بالاستحقاق",
      ascCreationDate: "الأحدث بالتكليف",
      desCreationDate: "الأقدم بالتكليف",
      deletePopupTitle: "تأكيد حذف مهمة",
      deleteConfirmMsg1: "هل تريد حذف المهمة التالية من مهام القضية؟",
      deleteConfirmMsg2:
        "عند تأكيد الحذف، سيتم إلغاء المهمة بشكل دائم، وسيتم إرسال تنويه بالالغاء لعضو الفريق المسند له المهمة",
      taskAdmin: "المكلف بالمهمة",
      completeTaskPopupTitle: "تأكيد انهاء مهمة",
      completeTaskConfirmMsg1: "هل تريد انهاء المهمة التالية من مهام القضية؟",
      completeTaskConfirmMsg2:
        "عند تأكيد الانهاء سيتم انهاء المهمة بشكل دائم، وسيتم إرسال تنويه بالانهاء لعضو الفريق المسند له المهمة",
      emptyText1: "لم تقم باضافة اي مهمة",
      emptyText2: "قم بإضافة مهام القضية",
    },
    teamMembersNo: "عدد الاشخاص",
    teamMembers: {
      addTeamMember: "إضافة عضو فريق",
      oneMember: "عضو",
      multiMember: "أعضاء",
      person: "{count, plural, =0 {# اشخاص} one {# اشخاص} other {# اشخاص}}",
      shares: "المشاركات",
      addation: "الإضافة",
      addedBy: " تمت إضافته بواسطة",
      shareIn: " شارك في ",
      session: "جلسة",
      sessions: "جلسات",
      neverShare: "لم يشارك بعد",
      task: "مهمه",
      tasks: "مهام",
      neverTask: "لم يكلف بعد",
      complete: "أكمل",
      addMember: "إضافة اعضاء فريق",
      teamMember: "عضو الفريق",
      choosen: "تم اختيار",
      deletePopupTitle: "تأكيد حذف عضو الفريق",
      deleteConfirmMsg1: "هل تود حذف الموظف التالي من فريق عمل القضية؟",
      deleteConfirmMsg2:
        "عند الحذف لن يتمكن عضو الفريق من الوصول للقضية ومتابعة العمل عليها،",
      theTasks: "المهام",
      theShare: "المشاركة",
      removeFromCase: "إزالة من القضية",
    },
    events: {
      events: "الاحداث",
      event: "{count, plural, =0 {# احداث} one {# احداث} other {# احداث}}",
      createEvent: "إنشاء حدث",
      editEvent: "تعديل حدث",
      reminders: "تنبيهات",
      sentByemail: "يرسل على الإيميل",
      sentBynotification: "يرسل في إشعار",
      sentByemailAndNotification: "يرسل في ايميل واشعار",
      changedEventDateWarning:
        "تم تعديل تاريخ الحدث، نوصي بمراجعة إعدادات التنبيهات",
      remindAttendeesWarning:
        "سيتم تذكير المشاركين في الحدث بشكل تلقائي في المواعيد الآتية",
      reminderNotActivatedWarning: "سيتم تفعيل المنبه على التاريخ المحدد",
      reminder: "المنبه",
      noEventsFound: "لا يوجد احداث",
      upcoming: "قادم",
      current: "حالي",
      ended: "منتهي",
      allEvents: "كل الاحداث",
      myEventsOnly: "احداثي فقط",
      upcomingEvent: "الحدث القادم",
      lastEvent: "اخر حدث",
      eventsNotCompletedYet: "مشارك في {count} أحداث",
      shareInEvents: "{count} أحداث لم تنتهي بعد",
      addSession: "إضافة جلسة",
      editSession: "تعديل جلسة",
      seesionInfo: "بيانات الجلسة",
      attendens: "الحضور",
      datePlace: "المكان و الموعد",
      remindering: "التنبيهات",
    },
    history: {
      circleName: "اسم الدائرة",
      court: "محكمة",
      editCaseDision: "تعديل القرار",
      deleteCaseDision: "حذف القرار",
      addHistory: "إضافة قضية متعلقة بالدعوى",
      addHistoryTitle: "إضافة سيرة للقضية",
      editHistory: "تعديل سيرة للقضية",
      historyInfo: "بيانات المحل القضائي",
      rescheduleNextSession: "موعد الجلسة القادمة",
      emptyText1: "لم تقم باضافة اي سير قضية",
      emptyText2: "قم بإضافة سير قضية",
      deleteConfirmMsg1: "قد يتأثر تتابع تاريخ القضية عند الحذف، تأكيد؟",
      deleteConfirmMsg2: "سيتم حذف جميع القرارات المندرجة عند حذف تاريخ القضية",
      deleteHistoryPopupTitle: "تأكيد حذف سير الدعوى",
    },
    notifications: {
      assignedToTask: "تم إسناد مهمة إليك",
      unAssignedToTask: "تم إلغاء إسناد مهمة إليك",
      addedToEvent: "تمت إضافتك لحضور الحدث",
      addedToTeam: "تم إضافتك لفريق القضية",
      removeFromCase: "تم إزالتك من فريق القضية",
      removeFromEvent: "تم حذفك من حضور الحدث",
      updateTask: "يوجد تحديث للمهمة",
      updateEvent: "تم التحديث على الحدث",
      noNotifications: "لا توجد اشعارات",
      sessionParticipant: "تم دعوتك لحضور جلسة",
      sessionApproveReport: "تم أعتماد تقرير جلسة",
      updateSession: " تم تحديث الجلسة",
      sessionReminder: " تذكير جلسة: {title} ",
      reminder: "تذكير",
      event: "حدث",
      session: "جلسة",
      task: "مهمة",
      removeCase: "تم إزالة القضية",
      removeTask: "تم إزالة المهمة",
      removeEvent: "تم إزالة الحدث",
      add_comment: " تم إضافة تنويه: [ {title} ]",
      add_reply: " رد على تنويه: [ {title} ]",
      sessionRemovedParticipant: "تم ازالتك من  {title}",
      sessionDeleted: "تم حذف الجلسة",
      appointmentAssignee: "لديك موعد جديد",
      appointmentRemoveAssignee: "تم ازالتك من موعد",
      appointmentUpdated: "يوجد تعديل علي موعد",
      appointmentDeleted: "تم الغاء الموعد",
      appointmentReminder: " تذكير موعد: {title} ",
      appointment: "موعد"
    },
    users: {
      newUser: "إنشاء مستخدم",
      manageUsers: "إدارة المستخدم",
      addNewUser: "إضافة مستخدم جديد",
      allUsers: "كل المستخدمين",
      usersNo: "عدد المستخدمين",
      users:
        "{count, plural, =0 {# مستخدمين} one {# مستخدمين} other {# مستخدمين}}",
      noUsersFound: "لا يوجد أعضاء",
      status: "الحالة",
      inactive: "غير نشط",
      active: "نشط",
      suspended: "متوقف",
      expired: "منتهى الصلاحية",
      addJobTitle: "أضف مسمى وظيفي",
      assignedAt: "تم التعيين في",
      activatedOn: "تم التفعيل في",
      suspendedOn: "تم الوقف في",
      expiredOn: "تم انتهاء الصلاحية في",
      reactivatedOn: "تمت إعادة التفعيل في",
      activeAccount: "حساب مفعل",
      inactiveAccount: "حساب غير مفعل",
      suspendedAccount: "حساب متوقف",
      expiredAccount: "حساب منتهي الصلاحية",
      permissions: "صلاحيات",
      addedBy: "تمت اضافته بواسطة",
      addedAt: "تاريخ الإضافة",
      searchInUsers: "البحث في الاشخاص",
      all: "الكل",
      current: "الحاليين",
      removed: "المحذوفين",
      last_login: "اخر دخول",
    },
    comments: {
      reaction: "التفاعل",
      comments:
        "{count, plural, =0 {# تعليقات} one {# تعليقات} other {# تعليقات}}",
      send_to_client: "ترسل للعميل",
      send_to_team_members: "ترسل لفريق العمل",
      add_comment: "أضف تعليق",
      add_reply: "أضف رد",
      add_reply_to_comment: "أضف رد على",
      writeComment:'أكتب تعليقك هنا',
    },
    addFirm: {
      addNewFirm: "أضف شركة جديدة",
      newFirm: "شركة جديدة",
    },
    firms: {
      firms: "الشركات",
      firmsCount:
        "{count, plural, =0 {# الشركات} one {# الشركات} other {# الشركات}}",
      domain: "الدومين",
      creation_date: "تاريخ الإنشاء",
      cases_no: "عدد القضايا",
      clients_no: "عدد العملاء",
      users_no: "عدد المستخدمين",
      ownerName: "مالك الشركة",
      firm: "الشركة",
      ownerEmail: "إيميل مالك الشركة",
      infoAboutFirm: "معلومات عن الشركة",
      subdomain: "النطاق الفرعي",
      using: "الإستخدام",
      date: "التاريخ",
      status: "الحالة",
    },
    actions: {
      next: "التالي",
      add: "أضف",
      addition: "إضافة",
      logout: "تسجيل خروج",
      stillLoggedIn: 'البقاء متصلا',
      saveDraft: "حفظ كمسودة",
      saveAndToClient: 'حفظ و إرسال للعميل',
      upload: "ارفع",
      skip: "تخطى",
      save: "حفظ",
      back: "رجوع",
      cancel: "الغاء",
      remove: "مسح",
      signIn: "تسجيل",
      noOptions: "لا يوجد اختيارات",
      addToCaseHistory: "أضف إلى تاريخ القضية",
      addNewTask: "إضافة مهمة",
      addNewMember: "إضافة مستخدم",
      addNewEvent: "إضافة حدث جديد",
      dragAndDropOrBrowse: "قم بالسحب والإفلات أو التصفح للتحميل",
      sortBy: "ترتيب حسب",
      filterBy: "تنقية",
      publish: "اصدار",
      block: "حظر",
      unblock: "رفع الحظر",
      invite: "دعوة",
      strength: {
        weak: "ضعيف",
        medium: "متوسط",
        good: "جيد",
        strong: "قوى",
      },
      loading: "جارى التحميل",
      reactivate: "اعادة تفعيل",
      complete: "اكمال",
      suspend: "توقف",
      load_more: "تحميل المزيد",
      load_less: "تحميل أقل",
      start_search: "ابدء البحث",
      cancel_filter: "إلغاء الفلتر",
      clear_all: "مسح الكل",
      send: "إرسال",
      backToLogin: "الرجوع لصفحة الدخول",
      reply: "رد",
      addDecision: "أضف قرار",
      createInvoice: "انشاء فاتورة",
      create: "انشاء",
      edit: "تعديل",
      editInvoice: "تعديل فاتورة",
      preview: "عرض",
      issue: "إصدار",
      payment: "دفع",
      print: "طباعه",
      delete: "حذف",
      edit: "تعديل",
      cancelInvoice: "إلغاء الفاتورة",
      contactUs: "تواصل معنا",
      move: "نقل إلى",
      moveToFolder: "نقل إلى مجلد",
      rename: "إعادة تسمية",
      filterResult: "تصفية النتائج",
      resetResult: "إعادة تعيين",
      moreDetailsInFilter: "عوامل تصفيه أكثر",
      lessDetailsInFilter: "عوامل تصفيه أقل",
      gregorian: "ميلادي",
      hijri: "هجري",
      allStatus: "كل الحالات",
      lessStatus: "حالات أقل",
      uploadFile: "تحميل ملف",
      printCaseDetails: "طباعة ملخص القضية",
      editData: "تعديل البيانات",
      moreDetails: "تفاصيل اكثر",
      lessDetails: "تفاصيل اقل",
      addition: "إضافة",
      saveAndExit: "حفظ و خروج",
      sendNow: "راسلنا الان",
      cancelTask: "إنهاء المهمة",
      moveOnly: "نقل",
      downloadAll: "تحميل الكل",
      generateReport: "إعداد تقرير الجلسة",
      editReport: "تعديل تقرير الجلسة",
      addVerdict: "إضافة حكم",
      sessionResult: "تتيجة الجلسة",
      sessionResultEdit: "تعديل تتيجة الجلسة",
      approveSessionReport: "إعتماد التقرير",
      download: "تنزيل",
      view: "عرض",
      addAppointment: ' إضافة موعد',
      editAppointment: 'تعديل موعد',
      export: 'تصدير',
      markAsRead: 'تحديد الكل كمقروءة',
      editData: 'تعديل البيانات',
      printClientData: 'طباعة بيانات العميل',
      editContact: 'تعديل معلومات التواصل',
      addContactMember: 'إضافة مسؤول تواصل',
      editContactMember: 'تعديل مسؤول تواصل',
      returnInvoice: 'أصدار اشعار دائن',
      editReturnInvoice: 'تعديل اشعار دائن',
      import:'إستيراد',
      uploadSheet: 'تحميل القالب',
      returnInvoiceList: 'اظهار الاشعارات الدائنة',
      makeSure: 'تأكيد',
      switchToAccount: 'تحويلها لشركة',
      agree: 'موافق',
      createContract: 'إنشاء عـقـد',
      download: 'تحميل',
    },
    snackbar: {
      tenantCreated: "تم إضافة المكتب بنجاح",
      caseCreated: "تم إضافة القضية بنجاح",
      userCreated: "تم إضافة العضو بنجاح",
      clientCreated: "تم اضافة العميل بنجاح",
      representativeContactCreated: "تم اضافة مسؤول التواصل بنجاح",
      representativeContactUpdated: "تم تعديل مسؤول التواصل بنجاح",
      representativeContactDeleted: "تم حذف مسؤول التواصل بنجاح",
      clientUpdated: "تم تعديل العميل بنجاح",
      jobTitleCreated: "تم إضافة المسمى الوظيفي بنجاح",
      taskCreated: "تم إضافة المهمة بنجاح",
      teamMembersAdded: "تم إضافة اعضاء القضية بنجاح",
      teamMemberDeleted: "تم حذف العضو بنجاح",
      taskCompleted: "تم اكمال المهمة بنجاح",
      eventCreated: "تم إضافة الحدث بنجاح",
      caseHistoryCreated: "تم إضافة سيرة القضية بنجاح",
      caseDecisionCreated: "تم إضافة القرار بنجاح",
      casePartiesCreated: "تم إضافة طرف دعوى بنجاح",
      caseContactCreated: "تم إضافة العميل بنجاح",
      relationTitleCreated: "تم إضافة درجة علاقة بنجاح",
      taskCategoryCreated: "تم إضافة تصنيف بنجاح",
      taskUpdated: "تم تعديل المهمة بنجاح",
      eventUpdated: "تم تعديل الحدث  بنجاح",
      contactUpdated: "تم تعديل جهة الاتصال بنجاح",
      contactDeleted: "تم حذف جهة الاتصال بنجاح",
      partiesUpdated: "تم تعدىل طرف الدعوى بنجاح",
      historyUpdated: "تم تعديل تاريخ القضية بنجاح",
      historyDeleted: "تم حذف تاريخ القضية بنجاح",
      caseUpdated: "تم تعديل القضية بنجاح",
      partiesDeleted: "تم حذف طرف الدعوى بنجاح",
      caseDeleted: "تم حذف القضية: ",
      taskDeleted: "تم حذف المهمة بنجاح",
      eventDeleted: "تم حذف الحدث بنجاح",
      passwordChange: "تم تغير كلمة السر بنجاح",
      commentCreated: "تم إضافة التعليق بنجاح",
      replyCreated: "تم إضافة الرد بنجاح",
      caseDecisionUpdated: "تم تعديل القرار بنجاح",
      caseDecisionDeleted: "تم حذف القرار بنجاح",
      caseRelationCreated: "تم إضافة القضية المرتبطة بنجاح",
      caseRelationDeleted: "تم حذف القضية المرتبطة بنجاح",
      created: "تم الإضافة بنجاح",
      edited: "تم التعديل بنجاح",
      invoiceCreated: "تم إضافة الفاتورة بنجاح",
      invoiceReturned: 'تم إضافة أشعار دائن بنجاح',
      invoiceEdited: "تم تعديل الفاتورة بنجاح",
      contactUsMsgSent: "تم إرسال الرسالة",
      renameDone: "تم إعادة التسمية بنجاح",
      moveDone: "تم النقل بنجاح",
      deleteDone: "تم الحذف بنجاح",
      createFolderDone: "تم تحميل المجلد بنجاح",
      uploadFilesDone: "تم تحميل الملفات بنجاح",
      sessionCreated: "تم إضافة الجلسة بنجاح",
      sessionUpdated: "تم تعديل الجلسة بنجاح",
      sessionDeleted: "تم حذف الجلسة بنجاح",
      verdictCreated: "تم إضافة الحكم بنجاح",
      verdictUpdated: "تم تعديل الحكم بنجاح",
      verdictDeleted: "تم حذف الحكم بنجاح",
      HistoryCreated: "تم إضافة سير القضية بنجاح",
      sessionOutcomeCreated: "تم إضافة نتيجة الجلسة بنجاح",
      sessionReportCreated: "تم إضافة تقرير الجلسة بنجاح",
      sessionReportUpdated: "تم تعديل نتيجة الجلسة بنجاح",
      sessionOutcomeEdited: "تم تعديل نتيجة الجلسة بنجاح",
      reportApproved: "تم اعتماد التقرير بنجاح",
      appointmentCreated: "تم إضافة الموعد بنجاح",
      appointmentUpdated: "تم تعديل الموعد بنجاح",
      appointmentDeleted: "تم حذف الموعد بنجاح",
      fileDeleted: "تم حذف الملف بنجاح",
    
    },
    popups: {
      deleteUserWarning:
        "عند الحذف لن يتمكن عضو الفريق من الوصول للقضية ومتابعة العمل عليها، تأكيد؟",
      suspendUserWarning:
        "عند إيقاف الحساب، لن يتمكن المستخدم من الدخول إلى النظام على الإطلاق {linebreak} يمكنك إعادة تفعيل الحساب بعد إيقافه عند الحاجة، تأكيد؟",
      confirmUserDeletion: "تأكيد حذف عضو الفريق",
      confirmTaskCompletion: "تأكيد إتمام المهمة",
      completeTaskWarning:
        "أنت على وشك وضع علامة على هذه المهمة كمكتملة، تأكيد؟",
      confirmUserSuspend: "تأكيد إيقاف المستخدم",
      confirmContactDelete: "تأكيد حذف جهة الاتصال",
      warningContactDelete:
        "عند تأكيد الحذف، يتم إزالة بيانات جهة الإتصال بشكل دائم",
      confimDeleteCaseHistory: "تأكيد حذف تاريخ القضية",
      confirmDeleteCaseHistoryWarning:
        "قد يتأثر تتابع تاريخ القضية عند الحذف، تأكيد؟",
      confirmDeleteCaseDecisionsWarning:
        "سيتم حذف جميع القرارات المندرجة عند حذف تاريخ القضية.",
      warningCaseParties:
        "عند تأكيد الحذف، ستتم إزالة بيانات طرف الدعوى من القضية.",
      confirmDeleteCaseParties: "تأكيد حذف طرف الدعوى",
      confirmDeleteCase:
        "عند حذف القضية لن يتمكن أي من المستخدمين الوصول لها ومتابعة العمل عليها",
      confirmDeleteCaseTask: "تأكيد إلغاء المهمة",
      confirmDeleteTask:
        "عند تاكيد الالغاء سيتم حذف المهمة بشكل دائم، وسيتم إرسال تنويه بالالغاء لعضو الفريق المسند له المهمة",
      confirmDeleteCaseEvent: "تأكيد حذف الحدث",
      confirmDeleteEvent:
        "عند تأكيد الحذف، سيتم إلغاء الحدث بشكل دائم، وسيتم إرسال تنويه بالالغاء للمشاركين",
      warningDecisionDelete:
        "عند تأكيد الحذف، يتم إزالة بيانات هذا القرار بشكل دائم",
      warningRelationDelete:
        "عند تأكيد الحذف، يتم إزالة بيانات القضية المرتبطة بشكل دائم.",
      issuingMsg:
        "عند إصدار الفاتورة سيتم إرسالها بشكل تلقائي للعميل، ولن تتمكن من إجراء اي تعديلات عليها.",
      cancelInvoice: "أنت بصدد إلغاء فاتورة مصدرة تم إرسالها للعميل",
      cancelInvoiceConfirmation:
        "عند التأكيد سيتم إرسال رسالة تنوية للعميل بالإلغاء",
      cancelInreviewInvoiceConfirmation: "هل تريد إلغاء فاتورة تحت المراجعة؟",
      cancelDraftInvoiceConfirmation: "هل تريد إلغاء فاتورة مسودة؟",
      payInvoiceIssue: "هل تود تغيير حالة الفاتورة من “مصدرة” ل “تم الدفع”؟",
      payInvoicePymentdue:
        "هل تود تغيير حالة الفاتورة من “مستحقة الدفع” ل “تم الدفع”؟",
    },
    numbering: ["الاول", "الثانى", "الثالث", "الرابع", "الخامس"],
    numbering: ["الاول", "الثانى", "الثالث", "الرابع", "الخامس"],
    shared: {
      morning: "صباحا",
      evening: "مساءا",
      from: "من",
      to: "إلى",
      fromAsc: "من الأقدم",
      fromDesc: "من الأحدث",
    },
    invoices: {
      invoice: "فاتورة",
      title: "الفواتير",
      allInvoices: "كل الفواتير",
      allInvoicesCount:
        "{count, plural, =0 {# فاتورة} one {# فاتورة} other {# الفواتير}}",
      create: "إنشاء فاتورة",
      name: "الاسم",
      invoiceItemName: "اسم البند",
      enterItemName: "أدخل اسم البند",
      enterUnit: "أدخل الوحده",
      enterItemPrice: "أدخل سعر البند",
      price: "السعر",
      quantity: "الكمية",
      quantity1: "العدد",
      unit: "الوحدة",
      tax: "الضريبة",
      discount: "خصم",
      subTotal: "المجموع الفرعي",
      addNewItem: "اضف عنصر جديد",
      subtotals: "المجموع الفرعي",
      total: "الإجمالي شامل الضريبة",
      serial_number: "الرقم التسلسلي",
      addNewTax: "اضف ضريبة جديدة",
      percent: "النسبة",
      invoice_date: "تاريخ الفاتورة",
      invoice_filter_date: "تاريخ الفاتورة",
      due_date: "تاريخ الإستحقاق",
      invoiceStatusFilter: "حالة الفاتورة",
      status: "الحالة",
      invoice_number: "رقم الفاتورة",
      client: "العميل",
      case: "القضية",
      services: "الخدمات",
      createDate: "تاريخ الإصدار",
      actions: "الإجراءات",
      noInvoicesFound: "لا يوجد فواتير",
      view: "عرض",
      invoiceStatus: {
        issued: "أُصدِرت",
        draft: "مسودة",
        cancelled: "ملغاه",
        reviewed: "قيد المراجعة",
        inreview: "قيد المراجعة",
        paid: "تم الدفع",
        payment: "مستحقة الدفع",
        payment_due: "مستحقة الدفع",
        payment_card: "مستحقة الدفع",
        inreview_card: "قيد المراجعة",
      },
      beforeTax: "قبل الضريبة",
      issueInvoice: "إصدار الفاتورة",
      cancelInvoice: "إلغاء الفاتورة",
      paymentInvoice: "دفع الفاتورة",
      invoiceStatusChange: "تغير حالة الفاتورة",
      company: "شركة",
      member: "فرد",
      invoiceItems: "بنود الفاتورة",
      editInvoice: "تعديل فاتورة",
      invoiceTotal: "قيمة الفاتورة",
      RS: "ر.س",
      basicData: "بيانات اساسية",
      invoiceItemName: "اسم البند",
      addInvoiceItem: "أضف بند جديد",
      addationInvoiceItem: "إضافة بند جديد",
      addInvoiceTax: "أضف ضريبة جديد",
      addationInvoiceTax: "إضافة ضريبة جديد",
      choose: "اختياري",
      addInvoiceItemErrMsg: "من فضلك ادخل البيانات",
      number: "العدد",
      invoicesTotal: "قيمة الفواتير",
      firmSettingWarning1: "بيانات الشركة غير موجودة،",
      firmSettingWarning2:
        " الرجاء إضافة بيانات الشركة حتى تتمكن من إصدار الفاتورة.",
      addFirmInfo: "إضافة بيانات الشركة",
      returnRatio: 'نسبة الاسترجاع',
      returnAmount: 'مجموع الاسترجاع',
      returnValueDetails: 'تفاصيل المبلغ المرتجع',
      returnInvoicesForInvoiceNum: 'الأشعارات الدائنة لفاتورة رقم'
    },
    freeTrail: {
      createFreeTrail: "إنشاء تجربة مجانية",
      freeTrail: "تجربة مجانية",
      infoAboutOwner: "معلومات عن مالك الشركة",
      ownerName: "إسم مالك الشركة",
      ownerEmail: "البريد الإلكتروني لمالك الشركة",
      infoAboutFirm: "معلومات عن الشركة",
      firmNameAr: "إسم الشركة بالعربية",
      firmNameEn: "إسم الشركة بالانجليزية",
      firmLogo: "شعار الشركة",
      infoAboutSubdomain: "معلومات عن النطاق الفرعي",
      firmSubdomain: "النطاق الفرعي",
      forExample: "على سبيل المثال يمكنك جعله",
      example: "company.mustand.com",
      youCan: "يمكنك",
      resend: "إعادة الإرسال",
      after60Sec: "بعد 60 ثانية",
      welcomeToLegal: "مرحبا بك في لوحة القيادة المهنية للقانون",
      login: "تسجيل الدخول",
      otp: "رمز التحقق",
      sendOtpTo: "ادخل رمز التحقق المرسل إلى جوالك لقد أرسلنا رمز إلى",
      legalTitle:
        "منصة إلكترونية متجددة لإدارة أعمال مكاتب المحاماة وتنظيم المهام وإدارة القضايا.",
      searchForCases:
        "منصة إلكترونية متجددة لإدارة أعمال مكاتب المحاماة وتنظيم المهام وإدارة القضايا.",
      steps: {
        step1: "الخطوة رقم 1",
        step2: "الخطوة رقم 2",
        step3: "الخطوة رقم 3",
      },
      uploadLogo: 'تحميل أو إضافة شعار المؤسسة',
      thanksMsg1: 'شكراً على تواصلك معنا',
      thanksMsg2: ' لقد تلقينا طلبك بنجاح و سنقوم بالتواصل معك في اقرب وقت',
      thanksMsg3: "فضلاً تحقق من البريد الإلكتروني الخاص بك، سنرسل لك معلومات الدخول للرابط الخاص بمؤسستك، حيث يمكنك إدارة أعضاء الفريق وإنشاء القضايا",
      thanksMsg4: 'نتمنى لك تجربة متميزة',
      welcomeMsg: 'استمتع بجميع مـمـيـزات مستند بـشـكل مـجــــانـي لفـتـرة مـحـدودة',
      gotoLandingPage: 'العودة للصفحة الرئيسية',
      agreeOnTerms: 'الموافقة على الشروط و الأحكام',
      agreeOnTermsErrMsg: 'يجب الموافقة على الشروط و الأحكام'
    },
    common: {
      notFound: "لا يوجد",
      creationDate: "تاريخ الإنشاء",
      endDate: "تاريخ الإنتهاء",
      active: "نشطة",
      expired: "منتهية",
    },
    endFreeTrial: {
      head: "عذراً، الفترة التجريبية قد إنتهت",
      subhead1:
        "جميع القضايا والبيانات التي قمت بإدخالها محفوظة، يمكنك الوصول لجميع البيانات الخاصة بمكتبك عند الترقية والإشتراك.",
      subhead2:
        "يسعدنا تواصلك معنا لمعرفك تقييمك للفترة التجريبية، ومدى رضائك عن المنتج",
    },
    common: {
      notFound: "لا يوجد",
      creationDate: "تاريخ الإنشاء",
      endDate: "تاريخ الإنتهاء",
      active: "نشطة",
      expired: "منتهية",
      arrange: "الترتيب",
    },
    banner: {
      head: "الترقية وتفعيل الإشتراك",
      subHead1: "أوشكت الفترة التجريبية على الإنتهاء، متبقى",
      days: "{days} يوم",
      subHead2:
        "قم بترقية إشتراكك حتي يمكنك الوصول لحسابك ومتابعة أعمال فريقك على القضايا",
      upgrade: "قم بالترقية الان",
    },
    generalSettings: {
      title: "الإعدادات العامة",
      generalSettings: "الاعدادات العامة",
      hint: "يمكنك تكوين إعدادات الموقع العامة وتخزين الخيارات للبائع من قائمة الإعدادات هذه. يوفر ليجيت أكبر قدر من المرونة عندما يتعلق الأمر بإعداد مكتب المحاماه مع اختياراتك المخصصة.",
      dateHint: "يمكنك اختيار إعدادات التاريخ للموقع سواء هجري أو ميلادي",
      timeHint: "يمكنك تكوين إعدادات الوقت المناسب",
      smsHint: "يمكنك تكوين إعدادات الموقع العامة وتفعيل الرسائل النصية",
      save: "حفظ الاعدادات",
      generalInfo: "بيانات عامة",
      commericalInfo: "بيانات تجارية",
      bankInfo: "بيانات بنكية",
      automaticLogout: 'تسجيل الخروج التلقائي',
      activateAutomaticLogout: 'تفعيل تسجيل الخروج التلقائي',
      automaticLogoutMsg1: 'تسجيل الخروج بعد',
      automaticLogoutMsg2: 'من عدم استخدام المنصة',
      minute: 'دقيقة',
    },
    client: {
      followIssuesOnApp: "لمتابعة ملفات القضايا الخاصة بك",
      legitAppAvailable:
        "ليجيت متاح ل جميع الأجهزة “يمكنك متابعة ملفات القضايا الخاصة بك عن طريق تطبيق الجوال.",
    },
    clients: {
      clientType: "تحديد نوع العميل",
      clientInfo: "بيانات العميل",
      attachments: "المرفقات",
      persons: "أفراد",
      companies: "شركات",
      attachments2: "المرفقات",
      companyInfo: "بيانات الشركة",
      contactPerson: "مسؤول التواصل الرئيسي",
      addClient: "اضافة عميل",
      client: 'العميل',
      clientType: 'نوع العميل',
      contactMember: 'مسؤول التواصل',
      contactMember2: 'مسؤول تواصل',
      phoneNumber: 'رقم الهاتف',
      caseNumber: 'عدد القضايا',
      status: 'الحالـة',
      invoicesRequired: 'إجمالي المطالبات',
      actions: 'الإجراءات',
      inprogressStatus: 'جاري العمل',
      negotiationStatus: 'قيد التفاوض',
      noClients: 'لا يوجد عملاء',
      pendingPayments: 'يوجد مطالبات',
      noPendingPayments: 'لا يوجد مطالبات',
      activeCases: 'القضايا النشطة',
      mainInfo: 'البيانات الاساسية',
      contactInfo: 'معلومات التواصل',
      files: 'الملفات',
      deleteContactPopupTitle: "تأكيد حذف مسؤول تواصل",
      deleteConfirmMsg1:
        "هل تريد حذف مسؤول التواصل؟",
      deleteConfirmMsg2:
        "عند تأكيد الحذف، يتم إزالة بيانات مسؤول التواصل بشكل دائم",
        casesFolders: 'مجلدات القضايا',
        clientFile:"ملفات العميل",
        emptyText1: 'لم تقم باضافة اي عميل',
        emptyText2: 'قم بإضافة عملائك لمتابعة حالة العمل و المدفوعات',
        importClientList: 'إستيراد قائمة العملاء',
    },
    activityLog:{
      logsTitle: 'سجل الأنشطة',
      dateTime: "التاريخ و الوقت",
      user:'المستخدم',
      actionCategory: 'تصنيف الإجراء',
      categoryPlaceholder: 'قضايا او فواتير او إعدادات',
      actionType: 'نوع الإجراء',
      actionTypePlaceholder: "إضافة, تعديل, عرض ...",
      actionDescription: 'الوصف',
      users: 'المستخدمين',
      usersPlaceholder: 'قم بالتصفية بإسم المستخدم',
      noLogs: 'لا يوجد سجل للأنشطة',
      platform: 'المنصة',
    },
    importInstructions: {
      clients: 'العملاء',
      client: 'عميل',
      cases: 'القضايا',
      case: 'قضية',
      users: 'المستخدمين',
      user: 'مستخدم',
      importList: 'إستيراد قائمة ',
      uploadFormattedSheet: 'قم بتحميل قالب مستند و ملء قائمة',
      with: 'به',
      uploadFormattedSheetFirst:'إذا لم يكن لديك قالب قم بتحميل قالب مستند أولاً و إتباع الإرشادات بالأسفل',
      fillSheetInstructions: 'إرشادات ملء القالب:',
      instruction1: 'تأكد من ملء كل خانة بنوع البيانات الملائم لها سواء أرقام مثل رقم الهاتف او حروف مثل الإسم',
      instruction2: 'تأكد من ملء كل الخانات الإلزامية مثل: إسم العميل , نوعه سواء فرد او شركة و رقم جواله',
      uploadSheet: 'رفع الملف بعد ملء البيانات',
      uploadSheetMsg: 'يمكنك رفع الملف مباشرة إذا قمت بتحميل القالب قبل ذلك و ملء البيانات بالفعل',
      importDone: 'تم إستيراد',
      success: 'بنجاح',
      importFail: 'فشل إستيراد ملف',
      incorrectData: 'تنسيق البيانات غير صحيح',
      addation: 'إضافة',

    },
    validationKeys: {
      type: 'نوع العميل',
      name: 'اسم العميل',
      email: 'البريد الألكترونى',
      otp: 'رمز التحقق',
      job_title_id: 'الوظيفة',
      role: 'الدور',
      persons: "أفراد",
      comapnies: "شركات",
      [`company.name`]: 'اسم الشركة',
      [`emails.0.email`]: 'البريد الألكترونى',
      [`company.addresses.0.city`]: 'المدينة',
      [`company.addresses.0.country_id`]: 'الدولة',
      [`company.type_id`]: 'نوع الشركة',
      [`contact_name`]: 'اسم مسئول التواصل',
      [`phones.0.code`]: 'كود البلد',
      [`phones.0.phone`]: 'رقم الجوال',
      [`identities.0.identity_id`]: 'نوع الهوية',
      [`identities.0.value`]: 'رقم الهوية',
      [`case.main_class_id`]: 'تصنيف الدعوى الرئيسي',
      [`case.status_id`]: 'الحالة',
      [`case.sub_class_id`]: 'تصنيف الدعوى الفرعي',
      [`court.court_degree_id`]: 'درجة المحكمة',
      [`court.date`]: 'تاريخ القضية في ناجز',
      [`court.najiz_number`]: 'رقم القضية في ناجز',
      [`clients.0.status`]: 'نوع طرف الدعوى',
      [`managers.0`]: 'المكلف بالقضية',
      [`case.title`]: 'عنوان القضية',
      [`clients.0.id`]: 'العميل',
    },
    contracts: {
     createNewAccount: 'إنشاء حساب جديد',
     welcomeText: 'مرحبا بك في المنصة الإحترافية لإدارة الأعمال قانونية',
     addNewUser: 'تسجيل مستخدم جديد',
     phoneText: ' قم بإدخال رقم هاتفك لتتمكن من التسجيل في المنصة',
     otp: 'الرمز التأكيدي',
     addOtpMsg: 'تم إرسال رقم التحقق إلي رقم الهاتف التالي ',
     setPassword: 'تعيين كلمة المرور',
     passwordText: ' يمكن أن تتألف كلمة المرور من أيّ مجموعة من الأحرف والأرقام والرموز بحد أدني ٨ ولا يمكن استخدام علامات تشكيل أو أحرف مُشكّلة',
     addIdentity: 'التحقق من الهوية',
     addIdentityText: 'برجاء إضافة معلومات الهوية الوطنية او جواز السفر الخاص بك حتى تتمكن من التعاقد بشكل قانونى',
     personalInfo: 'المعلومات الشخصية',
     resendOtp: 'لاعادة ارسال كود التحقق, برجاء الضغط على',
     accountCreatedSuccessfully: 'تم إنشاء حسابك بنجاح',
     totalContracts: 'إجمالي العقود',
     waitingYourSign: 'بإنتظار توقيعك',
     waitingOthersSign: 'بانتظار توقيع الاخرين',
     contracts: 'العقود',
     profile: 'الملف الشخصي',
     otherMember: 'الطرف الآخر',
     contractName: 'إسم العقد',
     contractType: 'نوع العقد',
     lastUpdate: 'آخر تحديث',
     contractStatus: 'حالة العقد',
     endDate: 'تاريخ الإنتهاء',
     emptyText1: 'لم تقم بإنشاء أي عقد',
     emptyText2: 'ستظهر هنا قائمة العقود التي قمت بإنشائها او تم دعوتك للتوقيع عليها',
     rentContract: 'عقد إيجار',
     status: 'الحالة',
     creationDate: 'تاريخ الإنشاء',
     contractSignature: 'توقيع العقد',
     editRequest: 'طلب تعديل',
     download: 'تحميل',
     refuseSign: 'رفض التوقيع',
     assignedTo: 'مسند إلى',
     signatureDate: 'تاريخ التوقيع',
     client: 'العميل',
     contractTerms: 'بنود التعاقد',
     contractParties: 'أطراف التعاقد',
     firstParty: 'الطرف الأول (البائع/عميل مستند)',
     licenceDrive: 'رقم رخصة القيادة',
     secondeParty: 'الطرف الثاني (المشتري)',
     paymentInfo: 'الدفعات المالية',
     sellingInfo: 'بيانات البيع',
     totalSellingMonye: 'إجمالي مبلغ البيع',
     cashMonye: 'المدفوع نقداَ وقت توقيع العقد',
     remainingMonye: 'المبلغ المتبقي',
     penalCondention: 'مبلغ الشرط الجزائي',
     appointments: 'المواعيد',
     contractCreationDate: 'تاريخ تحرير العقد',
     deliveryDate: 'تاريخ التسليم',
     addTerm: 'إضافة بند',
     assignLawyer: 'إسناد العقد إلى:',
    }
  },
};
