export const FIRMS_REQUEST = "FIRMS_REQUEST";
export const FIRMS_RECIEVE = "FIRMS_RECIEVE";
// Replace with your request types
export const CREATE_TENANT_REQUEST = "CREATE_TENANT_REQUEST";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const UPLOAD_LOGO_REQUEST = "UPLOAD_LOGO_REQUEST";
// checkIfSubdomainIsTaken
export const CHECK_IF_SUBDOMAIN_IS_TAKEN_REQUEST =
  "CHECK_IF_SUBDOMAIN_IS_TAKEN_REQUEST";

// checkIfClientMailIsTaken
export const CHECK_IF_CLIENT_MAIL_IS_TAKEN_REQUEST =
"CHECK_IF_CLIENT_MAIL_IS_TAKEN_REQUEST";
export const CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE =
"CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE";

export const SWITCH_TO_REAL_ACCOUNT_REQUEST =
"SWITCH_TO_REAL_ACCOUNT_REQUEST";
export const SWITCH_TO_REAL_ACCOUNT_RECIEVE =
"SWITCH_TO_REAL_ACCOUNT_RECIEVE";

export const GET_SPEED_CONTRACTS_LIST_REQUEST = "GET_SPEED_CONTRACTS_LIST_REQUEST";
export const GET_SPEED_CONTRACTS_LIST = "GET_SPEED_CONTRACTS_LIST";

export const GET_SPEED_CONTRACTS_CLIENTS_REQUEST = "GET_SPEED_CONTRACTS_CLIENTS_REQUEST";
export const GET_SPEED_CONTRACTS_CLIENTS = "GET_SPEED_CONTRACTS_CLIENTS";

export const GET_SPEED_CONTRACTS_ASSIGNEES_REQUEST = "GET_SPEED_CONTRACTS_ASSIGNEES_REQUEST";
export const GET_SPEED_CONTRACTS_ASSIGNEES = "GET_SPEED_CONTRACTS_ASSIGNEES";

export const GET_SPEED_CONTRACTS_TYPES_REQUEST = "GET_SPEED_CONTRACTS_TYPES_REQUEST";
export const GET_SPEED_CONTRACTS_TYPES = "GET_SPEED_CONTRACTS_TYPES";

export const GET_SPEED_CONTRACTS_STATUS_REQUEST = "GET_SPEED_CONTRACTS_STATUS_REQUEST";
export const GET_SPEED_CONTRACTS_STATUS = "GET_SPEED_CONTRACTS_STATUS";

export const GET_SPEED_CONTRACT_DETAILS_REQUEST = "GET_SPEED_CONTRACT_DETAILS_REQUEST";
export const GET_SPEED_CONTRACT_DETAILS = "GET_SPEED_CONTRACT_DETAILS";

export const SUBMIT_CONTRACT_TERMS_REQUEST = 'SUBMIT_CONTRACT_TERMS_REQUEST';
export const SUBMIT_CONTRACT_TERMS = 'SUBMIT_CONTRACT_TERMS';

export const SUBMIT_ASSIGN_LAWYER_REQUEST = 'SUBMIT_ASSIGN_LAWYER_REQUEST';
export const SUBMIT_ASSIGN_LAWYER = 'SUBMIT_ASSIGN_LAWYER';

export const PREVIEW_CONTRACT_REQUEST = 'PREVIEW_CONTRACT_REQUEST';
export const PREVIEW_CONTRACT = 'PREVIEW_CONTRACT';

